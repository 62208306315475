export default [
  {
    path: '/invoices/list',
    name: 'drs-invoices',
    component: () => import('@/views/drs/invoices/Invoices.vue'),
    meta: {
      pageTitle: 'menu.invoices',
      breadcrumb: [
        {
          text: 'menu.invoices',
          to: '/invoices/list',
        },
        {
          text: 'common.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoices/preview/:id',
    name: 'drs-invoice-preview',
    component: () => import('@/views/drs/invoices/InvoicePreview.vue'),
    meta: {
      pageTitle: 'menu.invoices',
      breadcrumb: [
        {
          text: 'menu.invoices',
          to: '/invoices/list',
        },
        {
          text: 'menu.preview',
          to: '/invoices/preview/:id',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoices',
    name: 'drs-invoice-view',
    meta: {
      layout: 'full',
    },
    component: () => import('@/views/drs/invoices/InvoicePreview.vue'),
  },
]

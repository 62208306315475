import axios from 'axios'

const endpoint = '/api/brands'

export default {
  get: payload => axios.post(endpoint, { page: payload.page, paginate: payload.paginate }, { headers: { 'X-Page-Size': payload.perPage } }),
  create: payload => axios.post(`${endpoint}/save`, payload),
  update: payload => axios.put(endpoint, payload),
  delete: payload => axios.delete(`${endpoint}/${payload}`),
}

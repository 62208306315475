import axios from 'axios'

const endpoint = '/api/invoices'

export default {
  getAll: payload => axios.post(endpoint, { page: payload.page, paginate: payload.paginate, word: payload.word }, { headers: { 'X-Page-Size': payload.perPage } }),
  set: payload => axios.post(`${endpoint}/save`, payload),
  get: payload => axios.get(`${endpoint}/${payload}`),
  printInvoice: payload => axios.get(`${endpoint}/print-invoice/${payload}`),
  cancelInvoice: payload => axios.get(`${endpoint}/cancel-invoice/${payload}`),
  sendInvoice: payload => axios.get(`${endpoint}/send-email/${payload}`),
}

import axios from 'axios'

const endpoint = '/api/customers'

export default {
  get: payload => axios.post(endpoint, {
    page: payload.page,
    paginate: payload.paginate,
    word: payload.word,
    limit: payload.limit,
  }, { headers: { 'X-Page-Size': payload.perPage } }),
  searchCustomer: payload => axios.post(`${endpoint}/search-customers`, { page: payload.page, paginate: payload.paginate, word: payload.word }, { headers: { 'X-Page-Size': payload.perPage } }),
  getById: payload => axios.get(`${endpoint}/${payload}`),
  save: payload => axios.post(`${endpoint}/save`, payload),
  deleteImage: payload => axios.delete(`${endpoint}/image/${payload}`),
}

import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import envs from '@/@helpers/envs'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import ability from '@/libs/acl/ability'
import auth from '@/models/auth'
import apps from './routes/apps'
import products from './routes/drs/products'
import customers from './routes/drs/customers'
import invoices from './routes/drs/invoices'
import budgets from './routes/drs/budgets'
import users from './routes/drs/users'
import finances from './routes/drs/finances'
import orders from './routes/drs/orders'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: '/customers/agenda' },
    ...customers,
    ...users,
    ...budgets,
    ...orders,
    ...finances,
    ...invoices,
    ...products,
    ...pages,
    ...apps,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

async function logout() {
  try {
    await auth.logout()
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

    // Remove userData from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('geoToken')

    // Reset ability
    ability.update(initialAbility)
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.error(e)
  }
}

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn && to.name !== 'auth-confirm-account' && to.name !== 'drs-accept-budget' && to.name !== 'drs-budget-view' && to.name !== 'drs-invoice-view') return next({ name: 'auth-login' })

    // If user is on auth-confirm-account route but is not logged in
    if (!isLoggedIn
      && (to.name === 'auth-confirm-account'
      || to.name === 'drs-accept-budget'
      || to.name === 'drs-budget-view'
      || to.name === 'drs-invoice-view')) {
      axios.defaults.baseURL = envs.baseURL
      return next()
    }

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // if logged in and on auth-confirm-account
  if (to.name === 'auth-confirm-account') {
    logout()
    axios.defaults.baseURL = envs.baseURL
    return next()
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    // if (invalidToken()) return next({ name: 'auth-login' })
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

import axios from 'axios'

const endpoint = '/api/users'

export default {
  getAll: payload => axios.post(endpoint, { page: payload.page, paginate: payload.paginate }, { headers: { 'X-Page-Size': payload.perPage } }),
  set: payload => axios.post(`${endpoint}/save`, payload),
  getById: payload => axios.get(`${endpoint}/${payload.userId}`),
  confirmAccount: (payload, token) => axios.post(`${endpoint}/set-password?token=${token}`, payload),
}

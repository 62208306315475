import axios from 'axios'

const endpoint = '/api/finances'

export default {
  getAll: payload => axios.post(endpoint, { page: payload.page, paginate: payload.paginate, word: payload.word }, { headers: { 'X-Page-Size': payload.perPage } }),
  get: payload => axios.get(`${endpoint}/${payload}`),
  save: payload => axios.post(`${endpoint}/save`, payload),
  destroy: payload => axios.delete(`${endpoint}/delete/${payload}`),
}

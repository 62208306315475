import axios from 'axios'

const endpoint = '/api/budgets'

export default {
  getAll: payload => axios.post(endpoint, { page: payload.page, paginate: payload.paginate, word: payload.word }, { headers: { 'X-Page-Size': payload.perPage } }),
  get: payload => axios.get(`${endpoint}/${payload}`),
  set: payload => axios.post(`${endpoint}/save`, payload),
  sendInvoice: payload => axios.post(`${endpoint}/send-email/${payload}`),
  acceptBudget: payload => axios.post(`${endpoint}/accept?budget_id=${payload}`),
  changeStatusBudget: payload => axios.post(`${endpoint}/change-status`, payload),
  sendBudget: payload => axios.get(`${endpoint}/send-budget/${payload}`),
  printBudget: payload => axios.get(`${endpoint}/print-budget/${payload}`),
  deleteBudget: payload => axios.get(`${endpoint}/delete/${payload}`),
}
